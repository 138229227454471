<template>
    <IndexSearchBar/>
    <IndexTags/>
    <IndexServerList filter="default"/>
</template>

<script setup>
defineComponent({
    name: "ServerListPage",
});

if (useRoute().params.page) {
    useServerSeoMeta({
        robots: "noindex",
    });
}

useServerHead({
    link: [
        {
            rel: "canonical",
            href: "https://www.minecraftiplist.com/",
        },
    ],
});
</script>